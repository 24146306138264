<template>
    <div class="about-list fix-background">
        <div class="section" ref="sectionBox" @touchstart="touchstart" @touchmove="touchmove">
            <div class="header-menu">
                <span>
                    <img src="@/assets/images/sidebar/arrow-left.png" alt class="menu-info" @click.prevent="goBack()" />
                </span>
            </div>
            <!-- <div class="navbar">
                <van-icon
                    name="arrow-left"
                    class="back"
                    @click="goBack()"
                />
            </div> -->
            <div class="content">
                <h1>{{$t('title')}}</h1>
                <br>
                <h6>{{$t('dataTime')}}</h6>
                <p>
                    {{$t('data.descr')}}
                </p>
                <br>

                <h3>
                    {{$t('title1-h')}}
                </h3>
                <p v-for="(item, index) in $t('title1')" :key="`title1${index}}`">
                    {{item}}
                </p>

                <br>
                <h3>
                    {{$t('title2-h')}}
                </h3>
                <h4>
                    {{$t('title2-h1h')}}
                </h4>
                <p v-for="(item, index) in $t('title2-h1')" :key="`title2${index}}`">
                    {{item}}
                </p>

                <br>
                <h4>
                    {{$t('title2-h2h')}}
                </h4>
                <p v-for="(item, index) in $t('title2-h2')" :key="`title3${index}}`">
                    {{item}}
                </p>

                <br>
                <h4>
                    {{$t('title2-h3h')}}
                </h4>
                <p v-for="(item, index) in $t('title2-h3')" :key="`title4${index}}`">
                    {{item}}
                </p>

                <br>
                <h3>
                    {{$t('title3-h')}}
                </h3>
                <p v-for="(item, index) in $t('title3')" :key="`title5${index}}`">
                    {{item}}
                </p>
                <br>
                <h3>
                    {{$t('title4-h')}}
                </h3>
                <p v-for="(item, index) in $t('title4')" :key="`title6${index}}`">
                    {{item}}
                </p>
                <br>
                <h3>
                    {{$t('title5-h')}}
                </h3>
                <p>
                    <span v-html="$t('title5.0')"></span>
                </p>
                <br>
                <h4>
                    {{$t('title5-h1h')}}
                </h4>
                <p v-for="(item, index) in $t('title5-h1')" :key="`title7${index}}`">
                    {{item}}
                </p>
                <br>
                <h4>
                    {{$t('title5-h2h')}}
                </h4>
                <p v-for="(item, index) in $t('title5-h2')" :key="`title8${index}}`">
                    {{item}}
                </p>

                <br>
                <h3>
                    {{$t('title6-h')}}
                </h3>
                <h4>
                    {{$t('title6-h1h')}}
                </h4>
                <p v-for="(item, index) in $t('title6-h1')" :key="`title9${index}}`">
                    <!-- {{item}} -->
                    <span v-html="item"></span>
                </p>
                <br>
                <h4>
                    {{$t('title6-h2h')}}
                </h4>
                <p v-for="(item, index) in $t('title6-h2')" :key="`title10${index}}`">
                    {{item}}
                </p>
                <br>
                <h4>
                    {{$t('title6-h3h')}}
                </h4>
                <p v-for="(item, index) in $t('title6-h3')" :key="`title11${index}}`">
                    {{item}}
                </p>
                <br>
                <h4>
                    {{$t('title6-h4h')}}
                </h4>
                <p v-for="(item, index) in $t('title6-h4')" :key="`title12${index}}`">
                    {{item}}
                </p>
                <br>
                <h4>
                    {{$t('title6-h5h')}}
                </h4>
                <p >
                    {{$t('title6-h5.0')}}
                </p>

                <br>
                <h4>
                    {{$t('title6-h6h')}}
                </h4>
                <p v-for="(item, index) in $t('title6-h6')" :key="`title13${index}}`">
                    {{item}}
                </p>
                <br>
                <h4>
                    {{$t('title6-h7h')}}
                </h4>
                <p v-for="(item, index) in $t('title6-h7')" :key="`title14${index}}`">
                    {{item}}
                </p>
                <br>
                <h4>
                    {{$t('title6-h8h')}}
                </h4>
                <p v-for="(item, index) in $t('title6-h8')" :key="`title15${index}}`">
                    {{item}}
                </p>

                <br>
                <h3>
                    {{$t('title7h')}}
                </h3>
                <p v-for="(item, index) in $t('title7')" :key="`title16${index}}`">
                    {{item}}
                </p>
                <br>
                <h3>
                    {{$t('title8h')}}
                </h3>
                <p v-for="(item, index) in $t('title8')" :key="`title17${index}}`">
                    {{item}}
                </p>

                <br>
                <h3>
                    {{$t('title9h')}}
                </h3>
                <p v-for="(item, index) in $t('title9')" :key="`title18${index}}`">
                    {{item}}
                </p>
            </div>
        </div>
        <div class="fixedBox"></div>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    components: {},
    data() {
        return {}
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        touchstart () {
            this.lastY = event.changedTouches[0].clientY // 点击屏幕时记    录最后一次Y坐标
        },
        touchmove () {
            let y = event.changedTouches[0].clientY
            let st = this.$refs.sectionBox.scrollTop // 滚动条高度
            if (y >= this.lastY && st <= 0) { //若滚动到顶部且为下拉情况
                this.lastY = y
                event.preventDefault()
            }
            let clientHeight = document.querySelector('.section').clientHeight
            let scrollHeight = document.querySelector('.section').scrollHeight
            if ((st + clientHeight === scrollHeight) && y < this.lastY) { // 若滚动到底部且为上拉情况
                this.lastY = y
                event.preventDefault()
            }
            this.lastY = y  
        }
    }
}
</script>

<style scoped lang="less">
.infofi {
    list-style-type: none;
}
.tel {
    text-decoration: none;
    color: #4a90e2;
}
.infonone {
    list-style-type: none;
    margin-left: 20px;
}
.info {
    list-style-type: decimal;
    margin-left: 20px;
}
.h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 8px 0;
}
.bold {
    font-weight: bold;
}
.about-list {
    width: 100%;
    background-image: linear-gradient(180deg, #262849, #07090f);
    font-size: 14px;
    color: #fff;
    position: relative;
    bottom: 0;
    .navbar {
        width: 100%;
        height: 30px;
        padding-right: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .back {
            font-size: 12px;
            padding-left: 21px;
            color: #fff;
        }
        /deep/ .van-icon-arrow-left:before {
            font-size: 14px;
            color: #fff;
        }
    }

    &.fix-background {
        height: 100%;
        overflow: hidden;
    }
}
.section {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 20px;
    -webkit-overflow-scrolling: touch;
    .content {
        padding:0 26px;
        text-align: left;
        h1{
            text-align: center;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 1px;
        }
        h6{
            font-size: 14px;
            font-weight: bolder;
        }
        h4, h3{
            margin: 4px 0 0;
        }
       /deep/ p  {
            color: #9496a6;
            margin: 10px 0 0;
            font-size: 14px;
            line-height: 19px;
            span{
                a{
                    color: #619bf3!important;
                }
            }
        }
    }
    .header-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        width: 100%;
        top: 0;
        z-index: 100;
        > span {
            img {
                display: block;
            }
        }
        .menu-info {
            width: 24px;
            height: 24px;
        }
    }
}
.fixedBox {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 7.68px;
}
.visbody-fit {
    width: 90%;
    padding: 0px 0 10px;
    margin: 0 auto;
    text-align: left;
    border-bottom: 1px solid #FFFFFF;
}
.notice-cont {
    text-align: left;
    margin: 0 auto;
    padding: 20px;
    font-size: 14px;
    line-height: 24px;
    .detail {
        color: #9496a6;
        .bold {
            color: #fff;
        }
    }
}
</style>
